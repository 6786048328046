import { Routes, Route, Navigate } from 'react-router-dom';
import Header from "./Components/Header/Header";
import About from "./Pages/About/About";
import Projects from "./Pages/Projects/Projects";
import ProjectPoets from "./Pages/Projects/ProjectPoets";
import ProjectNorr from "./Pages/Projects/ProjectNorr";
import Contact from "./Pages/Contact/Contact";
import Events from './Pages/EventPage/EventPage';
// import Reading from "./Pages/Reading/Reading";
import Error404 from './Pages/Error404/Error404';
import Footer from "./Components/Footer/Footer";

function App() {
  return (
    <main style={{minHeight: "100vh", display: "flex", flexDirection: "column"}}>
      <Header/>
      <Routes>
        <Route path="/" element={<About />} />
        {/* Comment out projects route for now - until Sophia wants to share projects */}
        {/* <Route path="/projects" element={<Projects />} />
        <Route path="/projects/norr" element={<ProjectNorr />} /> */}
        <Route path="/projects/poet-empress" element={<ProjectPoets />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/events" element={<Events />} />
        {/* Comment out the reading route for now - until Sophia reads more books. */}
        {/* <Route path="/reading" element={<Reading />} /> */}
        <Route path="/error" element={<Error404 />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <article style={{flex: "1"}}></article>
      <Footer/>
    </main>
  );
}

export default App;
