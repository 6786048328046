import {Link} from "react-router-dom";

//style imports
import "./nav.scss";

function Nav() {
    return (
        <nav className="nav">
            <ul className="nav__list">
                <li className="nav__li">
                    <Link to={'./'}>About</Link>
                </li>
                {/* Comment out projects for now - until Sophia wants to share
                <li className="nav__li">
                    <Link to={'./projects'}>Projects</Link>
                </li> */}
                <li className="nav__li">
                    <Link to={'./projects/poet-empress'}>The Poet Empress</Link>
                </li> 
                <li className="nav__li">
                    <Link to={'./events'}>Events</Link>
                </li> 
                <li className="nav__li">
                    <Link to={'./contact'}>Contact</Link>
                </li>
                {/* Comment out the reading route for now - until Sophia reads more books. */}
                {/* <li className="nav__li">
                    <Link to={'./reading'}>Reading</Link>
                </li>    */}
            </ul>
        </nav>
    )
}

export default Nav;