import { AiFillInstagram } from 'react-icons/ai';
import { PiButterflyFill } from "react-icons/pi";

import "./socialLinks.scss";

interface SocialLinksProps {
    width: number;
    height: number;
    size: number;
}

export default function SocialLinks({ width, height, size }: SocialLinksProps) {

    return (

        <div className="socialLinks__iconbox" style={{ width: width, height: height }}>
            <a href="https://www.instagram.com/storygoose" target="new" className="socialLinks__Link" style={{ width: size, height: size }}>
                <AiFillInstagram className="socialLinks__icon" />
            </a>
            <a href="https://bsky.app/profile/dragons.bsky.social" target="new" className="socialLinks__Link" style={{ width: size, height: size }}>
                <PiButterflyFill className="socialLinks__icon" />
            </a>
        </div>
    )
}