const eventData = [
    {name: "World Fantasy Convention", location: "Kansas City, MO", dateDisplay: "October 26-29, 2023", endDate: new Date("2023-10-29")},
    {name: "Dragon Con", location: "Atlanta, GA", dateDisplay: "August 31-September 4, 2023", endDate: new Date("2023-09-04")},
    {name: "Norwescon", location: "Seattle, WA", dateDisplay: "April 6-9, 2023", endDate: new Date("2023-04-10")},
    {name: "WorldCon", location: "Chicago, IL", dateDisplay: "September 1-5, 2022", endDate: new Date("2022-09-06")},
    {name: "4th Street Fantasy", location: "Minneapolis, MN", dateDisplay: "June 14-16, 2024", endDate: new Date("2024-06-17")},
    {name: "WorldCon 2024", location: "Glasgow, Scotland", dateDisplay: "August 8-12, 2024", endDate: new Date("2024-08-13")},
    {name: "WorldCon 2025", location: "Seattle, WA", dateDisplay: "August 13-17, 2025", endDate: new Date("2025-08-17")},
]

export default eventData;