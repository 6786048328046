import SocialLinks from "../SocialLinks/SocialLinks";
import strings from "../../Constants/strings";

import "./footer.scss";

function Footer () {
    return (
        <footer className="footer">
            <SocialLinks width={350} height={60} size={50}></SocialLinks>
        </footer>
    )
}

export default Footer;
