//asset imports
// import poets2Img from "../../Assets/Images/poets_lowres_img.jpg";
// import poetsImg from "../../Assets/Images/poets2_lowres_img.jpeg";
import poetsCoverImg from "../../Assets/Images/poets_cover.png";
import awdImg from "../../Assets/Images/pnwwa_award_img.png";

//constant imports
import strings from "../../Constants/strings";

//style imports
import "../../Styling/section.scss";
import "./projectPoets.scss";

export default function ProjectPoets() {
    return (
        <section className='poets__projectPage'>
            <h3 className="poets__title">{strings.books.poets}</h3>
            <div className="poets__imgBox poets__imgBox1">
                <img className="poets__image" src={poetsCoverImg} loading="lazy" alt="An image introducing the poet empress announcement for Jan 2026 on scenic mountain watercolor backdrop."/>
            </div>
            <p className="poets__content">{`In the declining, famine-ravished Azalea Dynasty, where poetry magic is forbidden to women, a peasant girl is unexpectedly chosen as concubine to the sadistic and invulnerable Prince Terren. For her own survival and the nation's, she must navigate dangerous court politics and learn to read in secret, so that she can kill him using a poem—a love poem.`}</p>
            <img className="poets__awdImg" src={awdImg} loading="lazy" alt="A finalist badge for the 2023 Pacific Northwest Writers' Association."/>
        </section>
    )
}