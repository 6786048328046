const strings = {
    authorName: "Shen Tao",
    authorFullTitle: "Shen Tao | Author",
    books: {
        poets: "The Poet Empress",
        norr: "The Chronicle of Norr"
    },
    mail1: "contact",
    mail2: "story",
    mail3: "goose.ca"
}

export default strings;